import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormsModule } from '@angular/forms';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingComponent } from './loading/loading.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { ReactiveFormsModule } from '@angular/forms';
import { PasajerosComponent } from './components/pasajeros/pasajeros.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputNumberModule } from 'primeng/inputnumber';
import { HeaderMenuComponent } from './components/users/header-menu/header-menu.component';
import { PanelMenuModule } from 'primeng/panelmenu';
import { SidebarModule } from 'primeng/sidebar';
import { TraslatePipe } from '@core/pipes/traslate.pipe';

@NgModule({
  declarations: [
    NavComponent,
    FooterComponent,
    CalendarComponent,
    PasajerosComponent,
    HeaderMenuComponent,
    TraslatePipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    SelectButtonModule,
    CalendarModule,
    ProgressSpinnerModule,
    LoadingComponent,
    ReactiveFormsModule,
    OverlayPanelModule,
    InputNumberModule,
    PanelMenuModule,
    SidebarModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    NavComponent,
    FooterComponent,
    LoadingComponent,
    CalendarComponent,
    PasajerosComponent,
    HeaderMenuComponent,
    TraslatePipe
  ]
})
export class SharedModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/languages/', '.json');
}
