import { Component, AfterViewInit, Inject } from '@angular/core';
import { FiltersService } from '@modules/landing/services/filters.service';
import { initializeCarousel, addSpecificSlide } from 'src/app/utils/carousel.utils';
import { cambiarVista } from 'src/app/utils/experience.utils';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements AfterViewInit {
  specificSlide: any = addSpecificSlide;
  changeView = cambiarVista;

  constructor(private filters: FiltersService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    private cookie: CookieService) { }

  cambiarVista(titulo: string, imagen: string, texto: string, experience: number, experience_name: string, videourl: string, horario: string) {

    this.changeView(titulo, imagen, texto, experience, experience_name, videourl, this.cookie.get('lang'), horario);

    this.filters.setFiltersLanding({
      exp: experience,
      date: this.storage.get('date') || this.filters.getFiltersLanding().date || new Date().toISOString().split('T')[0],
      adults: 0,
      children: 0
    });
    this.storage.set('exp', experience.toString());
  }

  scrollToSection(sectionId: any) {
    var targetSection = document.getElementById(sectionId);

    if (targetSection) {
      var offset =
        targetSection.offsetTop - (document.getElementById("menu")?.offsetHeight || 0);

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }

  ngAfterViewInit() {
    initializeCarousel();
  }
}
