import { filter } from 'rxjs';
import { Component, Inject } from '@angular/core';
import { FactureService } from '@modules/reservation/services/facture.service';
import { TranslateService } from '@ngx-translate/core';
import { PasajerosService } from '@shared/components/pasajeros/pasajeros.service';
import { CookieService } from 'ngx-cookie-service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FiltersService } from './services/filters.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent {
  constructor(private translate: TranslateService, private cookie: CookieService,
    @Inject(LOCAL_STORAGE) private storage: StorageService, private facture: FactureService, private pasajeros: PasajerosService,
    private filters: FiltersService
  ) {
    translate.addLangs(['en', 'es']);
    const lang = this.cookie.get('lang');
    if (lang) {
      translate.setDefaultLang(lang);
    } else {
      translate.setDefaultLang('es');
    }
  }

  ngOnInit(): void {
    this.storage.remove('isBoatSelected');
    this.storage.remove('rangeBPValue');
    this.storage.remove('rangeBP');
    this.storage.set('horarioBC', false);
    this.storage.set('children', 0);
    this.storage.set('adults', 1);
    this.storage.set('bpHoras', 0);
    this.storage.remove('isICSelected');
    this.storage.remove('adicionales');
    this.storage.remove('momentosMagicos');
    this.storage.remove('info');
    this.storage.remove('typeIC');
    this.storage.remove('id_servicios_add');
    this.storage.remove('code');
    this.storage.remove('transaction');
    this.storage.set('exp', 2)
    this.filters.setFiltersLanding({
      exp: 2,
      date: new Date().toISOString().split('T')[0],
      adults: 1,
      children: 0
    })

    this.facture.setIsRangeBPSelected(false);
    this.facture.setBPicon('pi pi-plus');
    this.pasajeros.setPasajeros(1, 0);
    this.facture.setAdults(1);
    this.facture.setChildren(0);
    this.facture.setIsICSelected(false);
    this.facture.clearAdicionales();

    if(this.storage.get('rload')){
      this.storage.remove('rload')
      window.location.reload()
    }else{
      this.storage.set('rload', true)
    }
  }
  
  rediriectToWhatsApp() {
    window.open('https://wa.me/573052765639', '_blank');
  }
}
