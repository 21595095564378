import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor() { }

  private loadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.loadingSubject.asObservable();

  private delayComplete = new BehaviorSubject<number>(0);

  // getDelayCompleteEvent() {
  //   return this.delayComplete.asObservable();
  // }

  setLoading(value: boolean) {
    this.loadingSubject.next(value);
  }

  setDelayComplete(identifier: number, other?: number) {
    if (identifier != 0) {
      this.loadingSubject.next(false);
      this.delayComplete.next(identifier);
    }
    if (other != null) {
      this.loadingSubject.next(false);
      this.delayComplete.next(other);
    }
  }

  // resetDelayComplete() {
  //   this.delayComplete.next(0);
  //   this.loadingSubject.next(false);
  // }
}
