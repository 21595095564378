import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslationService } from '../services/translation.service';
import { catchError, switchMap } from 'rxjs/operators';

@Pipe({
  name: 'traslate'
})
export class TraslatePipe implements PipeTransform {

  constructor(private translationService: TranslationService) { }

  transform(value: string, targetLang: string): Observable<string> {
    if (!value || !targetLang) {
      return of(value);
    }
    
    if (targetLang === 'es') {
      return of(value);
    }

    // Llama al servicio de traducción y maneja errores
    return this.translationService.translateText(value, targetLang).pipe(
      switchMap((response: any) => {
        const translation = response || 'Translation failed';
        return of(translation);
      }),
      catchError(() => of('Translation failed'))
    );
  }
}
