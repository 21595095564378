import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) private storage: StorageService) {
    this.info = this.storage.get('info') || this.info;
  }

  info: {
    nombre: string,
    apellido: string,
    documento: string,
    email: string,
    phone: string,
    direccion: string,
    offerts: string,
    promociones: string,
    terminos: string,
    id_tipo_documento: string | null,
    id_role: number | null
  } = {
      nombre: '',
      apellido: '',
      documento: '',
      email: '',
      phone: '',
      direccion: '',
      offerts: '',
      promociones: '',
      terminos: '',
      id_tipo_documento: null,
      id_role: null
    };

  getInfo() {
    return this.info;
  }

  updateInfo(info: any) {
    this.info = info;
  }

  getDocumentTypes(): Observable<any> {
    return this.http.get(`${environment.apiUrl}info/documentTypes`);
  }
}
