import { TypeExp } from "@core/enums/TypeExp";

export function cambiarVista(titulo: string, imagen: string, texto: string, experience: number, experience_name: string, videourl: string, lang: string, horario: string) {

    let title = document.getElementById("titulo");
    let image = document.getElementById("imagen");
    let text = document.getElementById("texto");
    let video = document.getElementById("videoyoutube") as HTMLAnchorElement;
    let horario_text = document.getElementById("horario");

    //iconos
    let imageicon1 = document.getElementById("imageicon1");
    let imageicon2 = document.getElementById("imageicon2");
    let imageicon3 = document.getElementById("imageicon3");
    let imageicon4 = document.getElementById("imageicon4");

    //texto iconos
    let icontext1 = document.getElementById("icontext1");
    let icontext2 = document.getElementById("icontext2");
    let icontext3 = document.getElementById("icontext3");
    let icontext4 = document.getElementById("icontext4");

    if (title && image && text && video && imageicon1 && imageicon2
        && imageicon3 && imageicon4 && icontext1 && icontext2 && icontext3 && icontext4 && horario_text) {

        if (lang === "es") {
            title.innerText = titulo;
            text.innerText = texto;
            horario_text.innerText = horario;
        } else {
            if (experience === TypeExp.ISLAS_PRIVADO) {
                title.innerText = "Private Island Tour";
                text.innerText = "Discover the exclusivity of the Rosario Islands with our Private Island Tour service. Navigate through crystal clear waters and white sandy beaches, creating unforgettable memories in this tropical paradise. Explore in comfort and style in one of the most dazzling destinations in Colombia and let yourself be carried away by the beauty of the Caribbean with us!";
                horario_text.innerText = "8:00 AM to 4:30 PM";
            } else if (experience === TypeExp.ISLAS_COMPARTIDO) {
                title.innerText = "Shared Island Tour";
                text.innerText = "Embark on our exciting shared tour in the Rosario Islands. Discover tropical paradises, dive into crystal clear waters and relax on dreamy beaches. Meet new people, share laughs and create unforgettable memories, join us for a day full of fun and discovery in the Colombian Caribbean!";
                horario_text.innerText = "8:00 AM to 4:30 PM";
            } else if (experience === TypeExp.BAHIA_PRIVADO) {
                title.innerText = "Private Bay Tour";
                text.innerText = "Explore the bay in total privacy with our exclusive tour, ideal for celebrations such as birthdays, romantic proposals or vow renewals. Customize your experience and enjoy a magical setting with attentive service and breathtaking views. Celebrate unforgettable moments of love, friendship and joy in a unique and exclusive environment.";
                horario_text.innerText = "5:00 pm to 12:00 PM";
            } else if (experience === TypeExp.BAHIA_COMPARTIDO) {
                title.innerText = "Shared Bay Tour";
                text.innerText = "Embark on our fun-filled, party-filled shared tour of the bay! Enjoy spectacular views in a lively atmosphere that will keep the energy high. Immerse yourself in the joy with our unique cocktail bar, an unforgettable evening full of fun and good company awaits you on this shared tour!";
                horario_text.innerText = "5:00 pm to 11:00 PM";
            } else if (experience === TypeExp.ECOTURISMO_PRIVADO) {
                title.innerText = "Private Ecotourism Tour";
                text.innerText = "Embark on an exciting Ecotourism tour through the Canal del Dique, discover breathtaking landscapes and connect with nature in an unforgettable journey that will stimulate your senses and enrich your adventurous spirit. Get your camera ready to capture biodiversity, birds and unique landscapes in this unforgettable experience.";
                horario_text.innerText = "8:00 AM to 4:30 PM";
            } else if (experience === TypeExp.ECOTURISMO_COMPARTIDO) {
                title.innerText = "Shared Ecotourism Tour";
                text.innerText = "Embark on an exciting Ecotourism tour through the Canal del Dique, where you will discover breathtaking landscapes and connect with nature in an unforgettable journey. Prepare your camera to capture the biodiversity and unique birds. Enjoy a typical lunch and share this experience with new friends, making every moment a unique connection with nature.";
                horario_text.innerText = "8:00 AM to 4:30 PM";
            } else if (experience === TypeExp.PESCA) {
                title.innerText = "Fishing Tour";
                text.innerText = "Experience the thrill of a day of fishing full of learning, fun and adventure. Discover the secrets of fishing in a spectacular setting, enjoy a delicious typical lunch and stay hydrated as you explore the waters in search of the perfect catch. A unique experience that combines the thrill of fishing with memorable moments and authentic local flavors.";
                horario_text.innerText = "6:00 AM to 2:00 PM";
            } else {
                title.innerText = "Corporate transportation";
                text.innerText = "Nada aun";
                horario_text.innerText = "Customizable";
            }
        }

        image.setAttribute("src", imagen);
        video.href = videourl;

        if (experience === TypeExp.ISLAS_PRIVADO) {
            //iconos
            imageicon1.setAttribute("src", "assets/icons exp/Tripulacion.svg");
            imageicon2.setAttribute("src", "assets/icons exp/Embarcacion.svg");
            imageicon3.setAttribute("src", "assets/icons exp/Agua.svg");
            imageicon4.setAttribute("src", "assets/icons exp/Snorkels.svg");

            //texto iconos
            if (lang === "es") {
                icontext1.innerText = "Tripulación calificada";
                icontext2.innerText = "Embarcación privada";
                icontext3.innerText = "Hidratación";
                icontext4.innerText = "8 Snorkels";
            } else {
                icontext1.innerText = "Qualified crew";
                icontext2.innerText = "Private boat";
                icontext3.innerText = "Hydration";
                icontext4.innerText = "8 Snorkels";
            }
        }
        else if (experience === TypeExp.ISLAS_COMPARTIDO) {
            //iconos
            imageicon1.setAttribute("src", "assets/icons exp/Tripulacion.svg");
            imageicon2.setAttribute("src", "assets/icons exp/Embarcacion.svg");
            imageicon3.setAttribute("src", "assets/icons exp/Snorkels.svg");
            imageicon4.setAttribute("src", "assets/icons exp/Almuerzo.svg");

            //texto iconos
            if (lang === "es") {
                icontext1.innerText = "Tripulación calificada";
                icontext2.innerText = "Embarcación de lujo";
                icontext3.innerText = "Snorkels";
                icontext4.innerText = "Almuerzo";
            } else {
                icontext1.innerText = "Qualified crew";
                icontext2.innerText = "Luxury boat";
                icontext3.innerText = "Snorkels";
                icontext4.innerText = "Lunch";
            }
        }
        else if (experience === TypeExp.BAHIA_PRIVADO) {
            //iconos
            imageicon1.setAttribute("src", "assets/icons exp/Tripulacion.svg");
            imageicon2.setAttribute("src", "assets/icons exp/Embarcacion.svg");
            imageicon3.setAttribute("src", "assets/icons exp/Agua.svg");
            imageicon4.setAttribute("src", "assets/icons exp/Hielo.svg");

            //texto iconos
            if (lang === "es") {
                icontext1.innerText = "Tripulación calificada";
                icontext2.innerText = "Embarcación de lujo";
                icontext3.innerText = "Hidratación";
                icontext4.innerText = "Hielo";
            } else {
                icontext1.innerText = "Qualified crew";
                icontext2.innerText = "Luxury boat";
                icontext3.innerText = "Hydration";
                icontext4.innerText = "Ice";
            }
        }
        else if (experience === TypeExp.BAHIA_COMPARTIDO) {
            //iconos
            imageicon1.setAttribute("src", "assets/icons exp/Tripulacion.svg");
            imageicon2.setAttribute("src", "assets/icons exp/Embarcacion.svg");
            imageicon3.setAttribute("src", "assets/icons exp/Hielo.svg");
            imageicon4.setAttribute("src", "assets/icons exp/Coctel.svg");

            //texto iconos
            if (lang === "es") {
                icontext1.innerText = "Tripulación calificada";
                icontext2.innerText = "Embarcación de lujo";
                icontext3.innerText = "Hielo";
                icontext4.innerText = "Cocteleria nacional";
            } else {
                icontext1.innerText = "Qualified crew";
                icontext2.innerText = "Luxury boat";
                icontext3.innerText = "Ice";
                icontext4.innerText = "National cocktails";
            }
        }
        else if (experience === TypeExp.ECOTURISMO_PRIVADO) {
            //iconos
            imageicon1.setAttribute("src", "assets/icons exp/Tripulacion.svg");
            imageicon2.setAttribute("src", "assets/icons exp/Embarcacion.svg");
            imageicon3.setAttribute("src", "assets/icons exp/Snorkels.svg");
            imageicon4.setAttribute("src", "assets/icons exp/Agua.svg");

            //texto iconos
            if (lang === "es") {
                icontext1.innerText = "Tripulación calificada";
                icontext2.innerText = "Embarcación de lujo";
                icontext3.innerText = "10 Snorkels";
                icontext4.innerText = "Hidratación";
            } else {
                icontext1.innerText = "Qualified crew";
                icontext2.innerText = "Luxury boat";
                icontext3.innerText = "10 Snorkels";
                icontext4.innerText = "Hydration";
            }
        }
        else if (experience === TypeExp.ECOTURISMO_COMPARTIDO) {
            //iconos
            imageicon1.setAttribute("src", "assets/icons exp/Tripulacion.svg");
            imageicon2.setAttribute("src", "assets/icons exp/Embarcacion.svg");
            imageicon3.setAttribute("src", "assets/icons exp/Equipo.svg");
            imageicon4.setAttribute("src", "assets/icons exp/Snacks.svg");

            //texto iconos
            if (lang === "es") {
                icontext1.innerText = "Tripulación calificada en pesca";
                icontext2.innerText = "Embarcación de lujo";
                icontext3.innerText = "Equipo de pesca";
                icontext4.innerText = "Snacks";
            } else {
                icontext1.innerText = "Qualified crew";
                icontext2.innerText = "Luxury boat";
                icontext3.innerText = "Fishing equipment";
                icontext4.innerText = "Snacks";
            }
        }
        else if (experience === TypeExp.PESCA) {
            //iconos
            imageicon1.setAttribute("src", "assets/icons exp/Tripulacion.svg");
            imageicon2.setAttribute("src", "assets/icons exp/Embarcacion.svg");
            imageicon3.setAttribute("src", "assets/icons exp/Snorkels.svg");
            imageicon4.setAttribute("src", "assets/icons exp/Agua.svg");

            //texto iconos
            if (lang === "es") {
                icontext1.innerText = "Tripulación calificada";
                icontext2.innerText = "Embarcación de lujo";
                icontext3.innerText = "10 Snorkels";
                icontext4.innerText = "Hidratación";
            } else {
                icontext1.innerText = "Qualified crew";
                icontext2.innerText = "Luxury boat";
                icontext3.innerText = "10 Snorkels";
                icontext4.innerText = "Hydration";
            }
        }
    }
}