import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class PasajerosService {

  pasajeros = new BehaviorSubject<{}>({adults: 1, children: 0});
  maximizePasajeros =  new BehaviorSubject<number>(0);

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {
    let adults = this.storage.get('adults');
    let children = this.storage.get('children');
    console.log("Adults: ", adults, "Children: ", children)

    if(adults) {
      console.log("Adults: ", adults, "Children: ", children)
      this.pasajeros.next({adults, children});
    }

    this.maximizePasajeros.next(this.storage.get('maximizePasajeros') ? this.storage.get('maximizePasajeros') : 0);
    console.log("MaximizePasajeros: ", this.getMaximizePasajeros().value);
  }

  setPasajeros(adults: number, children: number) {
    this.pasajeros.next({adults, children});
  }

  getPasajeros() {
    return this.pasajeros.asObservable();
  }

  setMaximizePasajeros(maximizePasajeros: number) {
    this.maximizePasajeros.next(maximizePasajeros);
  }

  getMaximizePasajeros() {
    return this.maximizePasajeros;
  }
}
