<div class="landing-contact-container">

    <div class="contenedor">

        <div class="agencia">

            <div class="galeria">

                <!-- <div class="sl activo"> </div>
                <div class="sl"> </div>
                <div class="sl"> </div>
                <div class="sl"> </div>
                <div class="sl"> </div>

                <div class="indicadores">
                    <div class="in" data-index="0"></div>
                    <div class="in" data-index="1"></div>
                    <div class="in" data-index="2"></div>
                    <div class="in" data-index="3"></div>
                </div> -->

                <!-- <div class="arrow arrowleft"></div>
                        <div class="arrow arrowright"></div>  -->
                <p-galleria [value]="photos" [numVisible]="5" [circular]="true" [showItemNavigators]="true"
                    [showThumbnails]="false" [autoPlay]="true" [style]="{'width': '30%','height': '50%'}">
                    
                    <ng-template pTemplate="item" let-item>
                        <img [src]="item" [alt]="item.alt"
                            style="width: 100%; height: 100%; object-fit: cover; display: block; border-radius: 10px;" />
                    </ng-template>
                </p-galleria>
            </div>

            <div class="Descrip">
                <div class="title">
                    <p> {{ 'conoce_nuestra' | translate }}</p>
                    <h1>{{ 'agencia' | translate }}</h1>
                </div>
                <!-- <p id="agenciainfo"> Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                    Morbi venenatis erat in massa lobortis commodo.</p>  -->
            </div>

        </div>

        <div class="mapa">
            <div class="Descrip">

                <div class="title">
                    <p>{{ 'estamos_aqui' | translate }}</p>
                    <h1>{{ 'visitanos' | translate }}</h1>
                </div>

                <div class="link" (click)="rediriectToAddres()">
                    <p>BOCAGRANDE-CR#3-8129 OF 1004</p> <svg xmlns="http://www.w3.org/2000/svg" width="78" height="78"
                        viewBox="0 0 78 78" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M39 0C28.6566 0 18.7368 4.10892 11.4228 11.4228C4.10892 18.7368 0 28.6566 0 39C0 49.3434 4.10892 59.2632 11.4228 66.5772C18.7368 73.8911 28.6566 78 39 78C49.3434 78 59.2632 73.8911 66.5772 66.5772C73.8911 59.2632 78 49.3434 78 39C78 28.6566 73.8911 18.7368 66.5772 11.4228C59.2632 4.10892 49.3434 0 39 0ZM53.5971 24.4029C53.9625 24.7693 54.2186 25.2303 54.3367 25.7342C54.4548 26.238 54.4302 26.7648 54.2657 27.2554L43.992 58.0766C43.824 58.5822 43.5146 59.029 43.1004 59.3641C42.6861 59.6991 42.1845 59.9084 41.655 59.967C41.1255 60.0257 40.5902 59.9312 40.1127 59.6949C39.6352 59.4586 39.2355 59.0903 38.961 58.6337L31.6179 46.3766L19.3719 39.039C18.9151 38.7649 18.5466 38.3656 18.3099 37.8885C18.0731 37.4113 17.9782 36.8763 18.0362 36.3468C18.0943 35.8174 18.3029 35.3156 18.6374 34.9011C18.9719 34.4866 19.4182 34.1766 19.9234 34.008L50.7446 23.7343C51.2352 23.5698 51.762 23.5452 52.2659 23.6633C52.7697 23.7814 53.2307 24.0375 53.5971 24.4029Z"
                            fill="#009FC5" />
                    </svg>
                </div>
            </div>

            <div class="map" (click)="rediriectToAddres()">
                <div class="direccion">
                    <img src="../../../../../assets/images/landing/mapa.svg" alt="">
                </div>
            </div>
        </div>

    </div>
</div>