<div class="header-landing">
    <div class="contenedormenu">
        <div class="logo" (click)="rediriectToHome()" style="cursor: pointer;"> <img
                src="../../../../../assets/icons/Logoblanco.svg" alt=""> </div>


        <div class="menu">
            <ng-container *ngIf="isResponsiveView; else noResponsive">
                <i class="pi pi-bars" [style]="{'font-size': '1.5rem', 'color': 'white'}" (click)="showMenuResponsive()"> </i>
            </ng-container>

            <ng-template #noResponsive>
                <nav id="navR">
                    <div class="perfil" (click)="logout()"><i class="pi pi-user"> </i>{{initialsName}}</div>
                </nav>
            </ng-template>

        </div>
    </div>
</div>

<p-sidebar [(visible)]="menuResponsive" position="right" styleClass="w-15rem" showCloseIcon="false"
    [style]="{'padding': '0px'}">
    <ng-template pTemplate="header">
        <div class="flex align-items-center gap-8">
            <span>
                <span class="font-bold">Hola, {{ initialsName }}</span>
            </span>

            <p-button *ngIf="environment=='usuarios'" icon="pi pi-sign-out" [style]="{'font-size': '.6rem', 'padding': '0%', 'width': '1.8rem', 'height': '1.8rem', 'color': '#009FC5'}" [rounded]="true" [text]="true"></p-button>
        </div>
    </ng-template>
    <p-panelMenu [model]="items"></p-panelMenu>
</p-sidebar>