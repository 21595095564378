import { Injectable, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { share, map, catchError } from 'rxjs/operators';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthenticationService } from '@core/services/authentication.service';
import { environment } from 'src/environments/environment.development';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

@Injectable()
export class tokenInterceptor implements HttpInterceptor {
  constructor(private auth: AuthenticationService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    private cookieService: CookieService,
    private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Obtener el token de la cookie
    const token = this.cookieService.get('token');
    console.log('token', token);

    // Clonar la solicitud y configurar para enviar cookies
    request = request.clone({
      withCredentials: true // Incluir esto para enviar cookies
    });

    return next.handle(request).pipe(
      share(),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Si la respuesta tiene un nuevo token en los headers, actualizar la cookie
          const newToken = event.headers.get('authorization');
          if (newToken) {
            this.cookieService.set('token', newToken);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 403) {
          this.auth.logout();
          this.router.navigate(['/login']);
        }
        return throwError(error);
      })
    );
  }

  validateError(status: any) {
    if ([401, 403].includes(status)) {
      this.auth.logout();
    }
  }

  // Función para determinar si una URL requiere token de autorización
  private requiresAuthorization(url: string): boolean {
    return url.startsWith(environment.apiUrl + 'costumer') || url.startsWith(environment.apiUrl + 'client') || url.startsWith(environment.apiUrl + 'admon');
  }
}