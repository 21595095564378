<form [formGroup]="myCalendar">
    <p-calendar formControlName="date" [showIcon]="true" appendTo="body" [minDate]="dateNow" [placeholder]="'Fecha'"
        [iconDisplay]="'input'" [style]=" { 'width': width, 'height': '2.6rem'}" [disabledDates]="disabledDate"
        (onSelect)="onDateSelect($event)" (onMonthChange)="MonthChange($event)">

        <ng-template pTemplate="date" let-date>
            <span [ngStyle]="{
            backgroundColor: isDisabledDate(date) ? 'red' : 'inherit',
            color: isDisabledDate(date) ? 'white' : 'inherit',
            borderRadius: isDisabledDate(date) ? '50%' : 'inherit',
            padding: isDisabledDate(date) ? '0.5rem' : 'inherit'}">{{date.day}}</span>
        </ng-template>

    </p-calendar>
</form>