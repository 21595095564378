<div class="footer-container">

    <div class="footer-info">

        <div class="footer-colum">
            <div class="footer-logo">
                <img src="../../../../assets/icons/Logoblanco.svg" alt="logo">
            </div>

            <div class="footer-data">
                <p class="footer-title">{{ 'quienes_somos' | translate }}</p>
                <p>{{ 'privacidad' | translate }}</p>
                <p>{{ 'terminos' | translate }}</p>
            </div>

        </div>

        <div class="footer-colum">
            <div class="footer-data">
                <p class="footer-title">{{ 'experiencias' | translate }}</p>
                <p style="cursor: pointer;"
                    (click)="cambiarVista('Tour Islas Privado', '../../../../assets/images/experiencias/islasPrivado.jpg',
                'Descubre la exclusividad de conocer Islas del Rosario con nuestro servicio de Tour Islas Privado. Navega por aguas cristalinas y playas de arena blanca creando recuerdos inolvidables en este paraíso tropical. Explora con comodidad y estilo en uno de los destinos más deslumbrantes de Colombia. ¡Déjate llevar por la belleza del Caribe con nosotros!', 1,'Tour islas privado', 'https://www.youtube.com/watch?v=PawyTr2zH7I', '8:30 AM a 4:30 PM')"
                    (click)="scrollToSection('experiencias')">
                    {{ 'tour_isla' | translate }}</p>
                <p style="cursor: pointer;"
                    (click)="cambiarVista('Tour Bahia Privado', '../../../../assets/images/experiencias/bahiaPrivada.jpg','Explora la bahía en total privacidad con nuestro tour exclusivo, ideal para celebraciones como cumpleaños, propuestas románticas o renovaciones de votos. Personaliza tu experiencia y disfruta de un entorno mágico con un servicio atento y vistas impresionantes. Celebra momentos inolvidables de amor, amistad y alegría en un ambiente único y exclusivo.', 3,'Tour bahia privado', 'https://www.youtube.com/watch?v=PawyTr2zH7I', '5:00 PM a 12:00 PM' )"
                    (click)="scrollToSection('experiencias')">{{ 'tour_bahia' | translate }}</p>
                <p style="cursor: pointer;"
                    (click)="cambiarVista('Ecoturismo Privado', '../../../../assets/images/experiencias/ecoturismo.jpg','Embárcate en un emocionante tour de Ecoturismo por el Canal del Dique, descubre paisajes impresionantes y conecta con la naturaleza en una jornada inolvidable que estimulara tus sentidos y enriquecerá tu espíritu aventurero. Prepara la cámara para capturar la biodiversidad, aves y paisajes únicos en esta experiencia inolvidable.', 5,'Ecoturismo privado', 'https://www.youtube.com/watch?v=PawyTr2zH7I', '8:30 AM a 4:30 PM' )"
                    (click)="scrollToSection('experiencias')">{{ 'ecoturismo' | translate }}</p>
                <p style="cursor: pointer;"
                    (click)="cambiarVista('Día de pesca', '../../../../assets/images/experiencias/pesca.jpg', 'Vive la emoción de un día de pesca lleno de aprendizaje, diversión y aventura. Descubre los secretos de la pesca en un entorno espectacular, disfruta de un almuerzo típico delicioso y mantente hidratado mientras exploras las aguas en busca de la captura perfecta. Una experiencia única que combina la emoción de la pesca con momentos memorables y auténticos sabores locales.', 7,'Pesca', 'https://www.youtube.com/watch?v=PawyTr2zH7I', '6:00 AM a 2:00 PM' )"
                    (click)="scrollToSection('experiencias')">{{ 'pesca' | translate }}</p>
                <p style="cursor: pointer;"
                    (click)="cambiarVista('Transporte empresarial', '../../../../assets/images/experiencias/empresarial.jpg', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis erat in massa lobortis commodo. Nunc nec magna viverra, sodales felis quis, tempor lorem. Sed lacinia semper neque eu ', 8, 'Empresarial en bahia', 'https://www.youtube.com/watch?v=DRUUWk_fLV0', 'Personalizado' )"
                    (click)="scrollToSection('experiencias')">{{ 'empresarial' | translate }}</p>
            </div>
        </div>

        <div class="footer-colum">
            <div class="footer-data">
                <p class="footer-title">{{ 'magicos' | translate }}</p>
                <p style="cursor: pointer;" (click)="specificSlide(0)" (click)="scrollToSection('momentos')">{{ 'pedida_de_mano' | translate }}</p>
                <p style="cursor: pointer;" (click)="specificSlide(1)" (click)="scrollToSection('momentos')">{{ 'aniversario' | translate }}
                </p>
                <p style="cursor: pointer;" (click)="specificSlide(2)" (click)="scrollToSection('momentos')">{{ 'cumpleaos' | translate }}
                </p>
                <p style="cursor: pointer;" (click)="specificSlide(3)" (click)="scrollToSection('momentos')">{{ 'experiencia_musical' | translate }}</p>
            </div>
        </div>

        <div class="footer-colum">
            <div class="footer-data">
                <p class="footer-title">{{ 'contacto' | translate }} </p>
                <p> {{ 'nuestra_compañia' | translate }}</p>
                <!-- <p>Correo </p> -->
                <p><i class="pi pi-envelope" style="margin-right: 10px;"> </i>cartagena&#64;island.com </p>
                <!-- <p>Numero</p> -->
                <p> <i class="pi pi-whatsapp" style="color: white; margin-right: 10px;"></i>+57 305 2765 639 </p>
                <p class="footer-title"> {{ 'redes_sociales' | translate }}</p>
                <a href="https://www.facebook.com/profile.php?id=100064210892144&mibextid=LQQJ4d" target="_blank"><i
                        class="pi pi-facebook" style="font-size: 1.3rem; color: white; margin-right: 25px;"></i></a>
                <a href="https://www.instagram.com/cartagena.island?igsh=OTdpYTdveG9vNnA5&utm_source=qr"
                    target="_blank"><i class="pi pi-instagram"
                        style="font-size: 1.3rem; color: white; margin-right: 25px;"></i></a>
                <a href="https://www.tiktok.com/@cartagenaisland?_t=8mgeKpVmVMc&_r=1" target="_blank"><i
                        class="pi pi-tiktok" style="font-size: 1.3rem; color: white; margin-right: 25px;"></i></a>
            </div>
        </div>



    </div>
</div>