import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DatesService {

  constructor(private httpClient: HttpClient) { }

  getDisponibilidad$(id_experience: number, mes: number, anio: number): Observable<any>{
    return this.httpClient.get(environment.apiUrl + 'experience/dates/'+id_experience +'/'+mes +'/'+anio);
  }

  getHorariosBC$(id_experience: number, date: string): Observable<any>{
    return this.httpClient.get(environment.apiUrl + 'experience/horariosBC/'+id_experience +'/'+date);
  }

}
