<p-toast [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }" />
<div class="landing-container">
    <nav class="landing-header">
        <!-- <video width="100%" height="100%" autoplay muted playsinline loop >
        <source src="../../../assets/images/landing/videolanding.webm">
     </video>  -->
        <app-landing-header>
        </app-landing-header>

    </nav>
    <section class="landing-experiences" id="experiencias">
        <app-landing-experiences></app-landing-experiences>
    </section>

    <section class="landing-magic" id="momentos">
        <app-landing-magic></app-landing-magic>
    </section>

    <section class="landing-contact" id="nosotros">
        <app-landing-contact></app-landing-contact>
    </section>

    <footer class="landing-footer" id="contacto">
        <app-footer></app-footer>
    </footer>

    <section class="whatsapp">
        <img src="../../assets/icons/Whatsappicon.png" alt="logo" (click)="rediriectToWhatsApp()">
    </section>
</div>