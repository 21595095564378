import { Renderer2 } from '@angular/core';

export function initializeHeader(renderer: Renderer2, nativeElement: any) {

    if (document) {
        window.onscroll = function () {
            scrollFunction();
        };

        window.onload = function () {
            var menu = document.getElementById("menu");
            var logo = document.getElementById("logo");

            if (menu && logo) {
                menu.style.transition = "padding 0.3s";
                logo.style.transition = "font-size 0.3s";
            }
        };

        function scrollFunction() {
            var menuElement = document.getElementById("menu");
            var logoElement = document.getElementById("logo");

            if (menuElement && logoElement) {
                if (
                    document.body.scrollTop > 1 ||
                    document.documentElement.scrollTop > 1
                ) {
                    menuElement.style.padding = "1px 0px";
                    menuElement.style.backgroundColor = "#3f717a80";
                    logoElement.style.fontSize = "1.5rem";
                } else {
                    menuElement.style.padding = "10px 0px";
                    menuElement.style.backgroundColor = "#91D8E480";
                    logoElement.style.fontSize = "2rem";
                }
            }
        }
    }
}

