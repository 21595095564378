<div class="experiences-container">

    <div class="experiences-title">
        <h1>{{ 'experiencia' | translate }}</h1>
    </div>

    <div class="experiences-titlemov" id="accionador">
        <h1>{{ 'experiencia' | translate }}</h1>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none">
            <path
                d="M8 7.46186L14.9776 0.186854C15.0338 0.126958 15.1006 0.0796052 15.174 0.0475357C15.2474 0.0154662 15.326 -0.000683738 15.4053 2.2019e-05C15.4846 0.000727776 15.563 0.0182747 15.6359 0.0516468C15.7088 0.0850189 15.7748 0.133554 15.8301 0.194443C15.8854 0.255332 15.9289 0.327366 15.958 0.406377C15.9872 0.485387 16.0014 0.569807 15.9999 0.654749C15.9984 0.73969 15.9812 0.823467 15.9492 0.901232C15.9173 0.978997 15.8713 1.04921 15.8139 1.1078L8.41816 8.818C8.30621 8.9347 8.15619 9 8 9C7.84381 9 7.69379 8.9347 7.58184 8.818L0.186105 1.1078C0.128688 1.04921 0.0826861 0.978997 0.0507597 0.901232C0.0188334 0.823467 0.00161769 0.73969 0.000108396 0.654749C-0.0014009 0.569807 0.012826 0.485387 0.0419666 0.406377C0.0711072 0.327366 0.114582 0.255332 0.169876 0.194443C0.22517 0.133554 0.291186 0.0850189 0.364101 0.0516468C0.437017 0.0182747 0.515386 0.000727776 0.594672 2.2019e-05C0.673958 -0.000683738 0.752588 0.0154662 0.826011 0.0475357C0.899435 0.0796052 0.966193 0.126958 1.02242 0.186854L8 7.46186Z"
                fill="#009FC5" />
        </svg>
    </div>


    <nav id="experiences-title">
        <ul>
            <li id="tourIsla" tabindex="0">
                {{ 'tour_isla' | translate }}
                <ul #tourBahiaSubMenu id="tourIslaSubMenu" class="submenu">
                    <li
                        (click)="cambiarVista('Tour Islas Privado', '../../../../../assets/images/experiencias/islasPrivado.jpg',
                    'Descubre la exclusividad de conocer Islas del Rosario con nuestro servicio de Tour Islas Privado. Navega por aguas cristalinas y playas de arena blanca creando recuerdos inolvidables en este paraíso tropical. Explora con comodidad y estilo en uno de los destinos más deslumbrantes de Colombia. ¡Déjate llevar por la belleza del Caribe con nosotros!', 1,'Tour islas privado', 'https://www.youtube.com/watch?v=PawyTr2zH7I', cookies.get('lang'), '8:30 AM a 4:30 PM' )">
                    {{ 'privado' | translate }}
                    </li>
                    <li
                        (click)="cambiarVista('Tour Islas Compartido', '../../../../../assets/images/experiencias/islasComparido.jpg','Embárcate en nuestro emocionante tour compartido en las Islas del Rosario. Descubre paraísos tropicales, sumérgete en aguas cristalinas y relájate en playas de ensueño. Conoce nuevas personas, comparte risas y crea recuerdos inolvidables. ¡Únete a nosotros para una jornada llena de diversión y descubrimientos en el Caribe colombiano!', 2,'Tour islas compartido', 'https://www.youtube.com/watch?v=PawyTr2zH7I', cookies.get('lang'), '8:30 AM a 4:30 PM' )">
                    {{ 'compartido' | translate }}
                    </li>
                </ul>
            </li>

            <li id="tourBahia" tabindex="0">
                {{ 'tour_bahia' | translate }}
                <ul #tourIslaSubMenu id="tourBahiaSubMenu" class="submenu">
                    <li
                        (click)="cambiarVista('Tour Bahia Privado', '../../../../../assets/images/experiencias/bahiaPrivada.jpg','Explora la bahía en total privacidad con nuestro tour exclusivo, ideal para celebraciones como cumpleaños, propuestas románticas o renovaciones de votos. Personaliza tu experiencia y disfruta de un entorno mágico con un servicio atento y vistas impresionantes. Celebra momentos inolvidables de amor, amistad y alegría en un ambiente único y exclusivo.', 3,'Tour bahia privado', 'https://www.youtube.com/watch?v=PawyTr2zH7I', cookies.get('lang'), '5:00 PM a 12:00 PM' )">
                        {{ 'privado' | translate }}
                    </li>
                    <li
                        (click)="cambiarVista('Tour Bahía Compartido', '../../../../../assets/images/experiencias/bahiaCompartido.jpg', '¡Embárcate en nuestro tour compartido por la bahía, lleno de fiesta y diversión! Disfruta de vistas espectaculares en un ambiente animado que mantendrá la energía alta. Sumérgete en la alegría con nuestra coctelería única. ¡Una tarde inolvidable llena de diversión y buena compañía te espera en este tour compartido!', 4,'Tour bahia compartido', 'https://www.youtube.com/watch?v=PawyTr2zH7I', cookies.get('lang'), '5:00 PM a 11:00 PM' )">
                        {{ 'compartido' | translate }}
                    </li>
                </ul>
            </li>

            <li id="Ecoturismo" tabindex="0">
                {{ 'ecoturismo' | translate }}

                <ul #EcoturismoSubMenu id="EcoturismoSubMenu" class="submenu">
                    <li
                        (click)="cambiarVista('Ecoturismo Privado', '../../../../../assets/images/experiencias/ecoturismo.jpg','Embárcate en un emocionante tour de Ecoturismo por el Canal del Dique, descubre paisajes impresionantes y conecta con la naturaleza en una jornada inolvidable que estimulara tus sentidos y enriquecerá tu espíritu aventurero. Prepara la cámara para capturar la biodiversidad, aves y paisajes únicos en esta experiencia inolvidable.', 5,'Ecoturismo privado', 'https://www.youtube.com/watch?v=PawyTr2zH7I', cookies.get('lang'), '8:30 AM a 4:30 PM' )">
                        {{ 'privado' | translate }}
                    </li>
                    <li
                        (click)="cambiarVista('Ecoturismo Compartido', '../../../../../assets/images/experiencias/ecoturismo.jpg', 'Embárcate en un emocionante tour de Ecoturismo por el Canal del Dique, donde descubrirás paisajes impresionantes y conectarás con la naturaleza en una jornada inolvidable. Prepara tu cámara para capturar la biodiversidad y aves únicas. Disfruta de un almuerzo típico y comparte esta experiencia con nuevos amigos, haciendo de cada momento una conexión única con la naturaleza.', 6,'Ecoturismo compartido', 'https://www.youtube.com/watch?v=PawyTr2zH7I', cookies.get('lang'), '8:30 AM a 4:30 PM' )">
                        {{ 'compartido' | translate }}
                    </li>
                </ul>
            </li>

            <li id="exp" tabindex="0"
                (click)="cambiarVista('Día de pesca', '../../../../../assets/images/experiencias/pesca.jpg', 'Vive la emoción de un día de pesca lleno de aprendizaje, diversión y aventura. Descubre los secretos de la pesca en un entorno espectacular, disfruta de un almuerzo típico delicioso y mantente hidratado mientras exploras las aguas en busca de la captura perfecta. Una experiencia única que combina la emoción de la pesca con momentos memorables y auténticos sabores locales.', 7,'Pesca', 'https://www.youtube.com/watch?v=PawyTr2zH7I', cookies.get('lang'), '6:00 AM a 2:00 PM' )">
                {{ 'pesca' | translate }}</li>

            <li id="exp" tabindex="0"
                (click)="cambiarVista('Transporte empresarial', '../../../../../assets/images/experiencias/empresarial.jpg', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi venenatis erat in massa lobortis commodo. Nunc nec magna viverra, sodales felis quis, tempor lorem. Sed lacinia semper neque eu ', 8, 'Empresarial en bahia', 'https://www.youtube.com/watch?v=DRUUWk_fLV0', cookies.get('lang'), 'Personalizado' )">
                {{ 'empresarial' | translate }}</li>
        </ul>
    </nav>

    <div class="contenido_exp">
        <div class="videoexp">
            <div class="videoexp-video">
                <img id="imagen" src="../../../../../assets/images/experiencias/islasComparido.jpg" alt="Imagen 1">
            </div>

            <div class="textexp">
                <p>{{ 'about_exp' | translate }} <a id=videoyoutube href="https://www.youtube.com/watch?v=PawyTr2zH7I"
                        target="_blank" style="cursor: pointer;">{{ 'here' | translate }}</a>
                </p>
            </div>
        </div>

        <div class="sec1">
            <h1 id="titulo">{{ 'tour_islas_compartido' | translate }}</h1>
            <p id="texto">{{ 'descripcion_islas_compartido' | translate }}</p>

            <div class="detallesexp">
                <div class="hora">
                    <h4>{{ 'horario_exp' | translate }}</h4>
                    <div class="horario">
                        <p id="horario"> 8:30 AM a 4:30 PM</p>
                    </div>
                </div>

                <div class="lugar">
                    <h4>{{ 'punto_encuentro' | translate }}</h4>
                    <div class="punto">
                        <p>Club Náutico de manga</p>
                    </div>
                </div>
            </div>

            <div class="sec2">
                <!-- <h3>Incluye</h3> -->
                <div id="iconos" class="iconos">
                    <div class="icon"> <img id="imageicon1" src="../../../../../assets/icons exp/Tripulacion.svg"
                            alt="">
                        <p id="icontext1">{{ 'tripulacion_calificada' | translate }}</p>
                    </div>

                    <div class="icon"><img id="imageicon2" src="../../../../../assets/icons exp/Embarcacion.svg" alt="">
                        <p id="icontext2">{{ 'embarcacion_lujosa' | translate }}</p>
                    </div>

                    <div class="icon"><img id="imageicon3" src="../../../../../assets/icons exp/Snorkels.svg" alt="">
                        <p id="icontext3">Snorkels</p>
                    </div>

                    <div class="icon"><img id="imageicon4" src="../../../../../assets/icons exp/Almuerzo.svg" alt="">
                        <p id="icontext4">{{ 'almuerzos' | translate }}</p>
                    </div>
                </div>
            </div>

            <button type="" (click)="rediriectToReservas()">{{ 'reserva_aqui' | translate }}</button>

        </div>

    </div>


<!-- Experiencia empresarial -->
<p-dialog [visible]="visableEmpresarial" class="login" [style]="{ 'width': '30%' }" [breakpoints]="{ '1199px': '75vw', '575px': '100vw' }">
    <ng-template pTemplate="headless">
        <div class="flex flex-column px-7 py-5 gap-3"
            style="border-radius: 12px;background-image: radial-gradient(circle at left top, #05b2dc, #009FC5);align-items: center;">
            <div id="logo"><img src="../../../../../assets/icons/Logoblanco.svg" alt=""></div>

            <label for="username" class="text-primary-50">{{ 'info_empresarial' | translate }}</label>

            <label for="password" class="text-primary-50 font-semibold">{{ 'redirigiendo' | translate }}</label>
            <svg class="svg" viewBox="25 25 50 50">
                <circle r="20" cy="50" cx="50"></circle>
            </svg>
            <p-button label="{{ 'cancelar' | translate }}" (click)="this.visableEmpresarial = false" [text]="true"
                styleClass="w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"
                class="center"></p-button>

        </div>
    </ng-template>
</p-dialog>