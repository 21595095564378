import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  languaje = 'es';

  getLanguaje() {
    return this.languaje;
  }

  setLanguaje(languaje: string) {
    this.languaje = languaje;
  }

}
