import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IFilterLanding } from '../../../core/models/IFilterLanding';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  loginModal = new BehaviorSubject<boolean>(false);
  loginModal$ = this.loginModal.asObservable();

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { 
    this.loginModal.next(this.storage.get('loginModal') || false);
  }

  private filtersLanding = new BehaviorSubject<IFilterLanding>({
    exp: 0,
    date: new Date().toDateString(),
    adults: 0,
    children: 0
  });

  filterOrders$ = this.filtersLanding.asObservable();

  setFiltersLanding(filters: IFilterLanding) {
    this.filtersLanding.next(filters);
  }

  getFiltersLanding() {
    return this.filtersLanding.getValue();
  }

  private boatSelected = new BehaviorSubject<number>(0);
  boatSelected$ = this.boatSelected.asObservable();

  setBoatSelected(id: number) {
    this.boatSelected.next(id);
  }

  getBoatSelected() {
    return this.boatSelected;
  }

  //Modal de inicio de sesion
  setLoginModal(value: boolean) {
    this.loginModal.next(value);
    this.storage.set('loginModal', value);
  }

  getLoginModal() {
    return this.loginModal.getValue();
  }
}
