import { Component, ViewChild, Inject, input, Input } from '@angular/core';
import { Overlay } from 'primeng/overlay';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { PasajerosService } from './pasajeros.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { FactureService } from '@modules/reservation/services/facture.service';

@Component({
  selector: 'app-pasajeros',
  templateUrl: './pasajeros.component.html',
  styleUrl: './pasajeros.component.css'
})
export class PasajerosComponent {
  @Input() exp: number = 0;

  @ViewChild('op') op: Overlay | undefined;

  constructor(private pasajerosService: PasajerosService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    private facture: FactureService) { }

  form: FormGroup = new FormGroup({
    pasajeros: new FormControl('', [Validators.required]),
    adults: new FormControl(1, [Validators.min(1)]),
    children: new FormControl(0, [Validators.required, Validators.min(0)])
  });

  maximoPasajerosAdults: number = 0;
  maximoPasajerosChildren: number = 0;

  ngOnInit(): void {
    this.pasajerosService.getPasajeros().subscribe((pasajeros: any) => {
      console.log("Adults: ", pasajeros.adults, "Children: ", pasajeros.children)
      this.form.get('adults')?.setValue(pasajeros.adults);
      if(this.exp != 4){
        this.form.get('children')?.setValue(pasajeros.children);
        this.form.get('pasajeros')?.setValue(`${pasajeros.adults} adultos, ${pasajeros.children} niños`);
      }else{
        this.form.get('pasajeros')?.setValue(`${pasajeros.adults} adultos`);
      }

    });

    this.pasajerosService.getMaximizePasajeros().subscribe((maximizePasajeros: number) => {
      console.log("Maximo de pasajeros: ", maximizePasajeros);
      this.maximoPasajerosAdults = maximizePasajeros;
    });

    this.maximoPasajerosChildren = this.maximoPasajerosAdults-1;
    console.log("Maximo de pasajeros ON INIT: ", this.maximoPasajerosAdults);
  }

  setPasajeros() {
    const adults = this.form.get('adults')?.value;
    const children = this.form.get('children')?.value;

    this.storage.set('adults', this.form.get('adults')?.value);
    this.storage.set('children', this.form.get('children')?.value);
    this.facture.setAdults(adults);
    this.facture.setChildren(children);
    this.form.get('pasajeros')?.setValue(`${adults} adultos, ${children} niños`);
    this.pasajerosService.setPasajeros(adults, children);
  }

  setAdults(adults: any) {
    console.log("Adults: ", adults)
    console.log("Children: ", this.form.get('children')?.value)
    if (adults >= this.maximoPasajerosAdults) {
      console.log("Adults: ", adults, "Maximo de pasajeros: ", this.maximoPasajerosAdults)
      this.form.get('children')?.setValue(0);
    } else {
      let resta = this.maximoPasajerosAdults - adults;
      this.maximoPasajerosChildren = resta;
      // this.form.get('children')?.setValue(resta);
    }
  }
}
