import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LandingService {

  constructor(public httpClient:HttpClient ) { }

  getExperience(id_experience: number, date: string){
    return this.httpClient.get(environment.apiUrl + 'experience/' + id_experience + '/' + date);
  }

  getCupos(id_experience: number, date: string){
    return this.httpClient.get(environment.apiUrl + 'experience/cupos/' + id_experience + '/' + date);
  }

  maximoPasajeros = new BehaviorSubject<number>(0);

  getMaximizePasajeros(){
    return this.maximoPasajeros;
  }

  setMaximizePasajeros(maximizePasajeros: number){
    this.maximoPasajeros.next(maximizePasajeros);
  }

  getCuposBC(id_experience: number, date: string, time: string){
    console.log("GOla")
    return this.httpClient.get(environment.apiUrl + 'experience/cupos/bc/' + id_experience + '/' + date + '/' + time);
  }
}
