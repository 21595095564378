import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '@core/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class authGuard {
  constructor(private auth: AuthenticationService) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot  
  ): Promise<boolean> {
    return this.auth.getCurrentUser() ? true : this.auth.logout();
  }
  
}
