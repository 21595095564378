export const environment = {
    production: true,
    apiUrl: 'https://api.cartagenaisland.com/',
    // apiUrl: 'http://localhost:3200/',
    // wompi_public_key: 'pub_prod_YIU1IhfUti40AixnQBbkBiDzuMp87QGk',
    wompi_public_key: 'pub_test_q44j9hX7mUs8hYrRIpS2bX7e8Xyo9WGB',
    wompi_api: 'https://sandbox.wompi.co/v1/transactions/',
    // wompi_api_prod: 'https://production.wompi.co/v1/transactions/'
    wompi_api_prod: 'https://sandbox.wompi.co/v1/transactions/'
};
