import { Component, HostListener } from '@angular/core';
import { ViewsService } from '@core/services/views.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CartagenaIsland';

  constructor(private view: ViewsService, private cookie: CookieService) {
    this.onResize(null);
  }

  ngOnInit() {
    if(!this.cookie.get('lang')){
      this.cookie.set('lang', 'es');
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    console.log('window.innerWidth', window.innerWidth);
    this.view.setResponsiveView(window.innerWidth < 400);
    console.log('isResponsiveView', window.innerWidth < 400);
  }
}
