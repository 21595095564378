<div class="magic-container">
    <div class="magic-text">
        <h1>{{ 'crea_momentos_magicos' | translate }}</h1>
        <p>{{ 'abordo_de_nuestras_embarciones' | translate }}</p>
    </div>
    <div class="wrapper">

        <div class="carousel">
            <div class="inner">
                <div class="slide active">
                    <h1>{{ 'pedida_de_mano' | translate }}</h1>
                    <h2 (click)="rediriectToReservas(3,32)">{{ 'reserva_aqui' | translate }}</h2>
                    <div class="gradient-overlay"></div>
                </div>

                <div class="slide">
                    <h1>{{ 'cumpleaos' | translate }}</h1>
                    <h2 (click)="rediriectToReservas(3,30)">{{ 'reserva_aqui' | translate }}</h2>
                    <div class="gradient-overlay"></div>
                </div>

                <div class="slide">
                    <h1>{{ 'aniversario' | translate }}</h1>
                    <h2 (click)="rediriectToReservas(3,31)">{{ 'reserva_aqui' | translate }}</h2>
                    <div class="gradient-overlay"></div>
                </div>

                <div class="slide">
                    <h1>{{ 'experiencia_musical' | translate }}</h1>
                    <h2 (click)="rediriectToReservas(3,33)">{{ 'reserva_aqui' | translate }}</h2>
                    <div class="gradient-overlay"></div>
                </div>

                <div class="indicators">
                    <div class="indicator" data-index="0"></div>
                    <div class="indicator" data-index="1"></div>
                    <div class="indicator" data-index="2"></div>
                    <div class="indicator" data-index="3"></div>
                </div>

                <div class="arrow arrow-left"></div>
                <div class="arrow arrow-right"></div>
            </div>
        </div>
    </div>