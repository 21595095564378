import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor() { }

  now = new Date();

  date: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() + 1));
  date$ = this.date.asObservable();

  setDate(date: Date): void {
    this.date.next(date);
  }

  getDate(): Date {
    return this.date.getValue();
  }

  // Bahia privada e islas privadas
  barcosOcupados: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  barcosOcupados$ = this.barcosOcupados.asObservable();

  setBarcosOcupados(barcos: any): void {
    this.barcosOcupados.next(barcos);
  }

  getBarcosOcupados(): any {
    return this.barcosOcupados.getValue();
  }

  disabledDate: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  disabledDate$ = this.disabledDate.asObservable();

  setDisabledDate(date: any): void {
    this.disabledDate.next(date);
  }

  getDisabledDate(): any {
    return this.disabledDate.getValue();
  }

  // Bahia compartida
  horariosBC: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  horariosBC$ = this.horariosBC.asObservable();

  setHorariosBC(horarios: any): void {
    this.horariosBC.next(horarios);
  }

  getHorariosBC(): any {
    return this.horariosBC.getValue();
  }
}
