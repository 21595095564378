import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from '@core/guards/auth.guard';
import { LandingComponent } from '@modules/landing/landing.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@modules/landing/landing.module').then(m => m.LandingModule)
    },
    {
        path: 'reservas',
        loadChildren: () => import('@modules/reservation/reservation.module').then(m => m.ReservationModule)
    },
    {
        path: 'dashboard',
        loadChildren: () => import('@modules/users/users.module').then(m => m.UsersModule),
        canActivate: [authGuard]
    },
    {
        path: '**',
        component: LandingComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
