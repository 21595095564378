import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }
  
  loginUser$(user: any): Observable<any>{
    return this.http.post(environment.apiUrl + 'auth/login', user);
  }

  registerUser$(user: any): Observable<any>{
    return this.http.post(environment.apiUrl + 'auth/register', user);
  }

  UserExist$(email: string, documento: string, phone: string): Observable<any>{
    return this.http.get(environment.apiUrl + 'auth/userExists/'+email+'/'+documento+'/'+phone);
  }

  getRoles$(id_user: number): Observable<any>{
    return this.http.get(environment.apiUrl + 'auth/getRol/'+id_user);
  }

  generateCodeEmail$(email: string): Observable<any>{
    return this.http.post(environment.apiUrl + 'auth/generateCodeForgotPassword', {email});
  }

  verifyCodeEmail$(email: string, code: string): Observable<any>{
    return this.http.post(environment.apiUrl + 'auth/verifyCodeForgotPassword', {email, code});
  }

  changePassword$(email: string, password: string): Observable<any>{
    return this.http.post(environment.apiUrl + 'auth/resetPassword', {email, password});
  }
}