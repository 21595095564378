import { Component, Input, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CalendarService } from './calendar.service';
import { LandingService } from '@modules/landing/services/landing.service';
import { DatesService } from '@modules/landing/services/dates.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { PasajerosService } from '../pasajeros/pasajeros.service';
import { FactureService } from '@modules/reservation/services/facture.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.css'
})
export class CalendarComponent {
  @Input() width: string = '54%';
  @Input() exp: number = 0;

  constructor(private calendar: CalendarService, private experience: LandingService,
    private dates: DatesService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    private pasajeros: PasajerosService, private facture: FactureService) { }

  disabledDate: any[] = [];
  now = new Date();
  dateNow = new Date();

  myCalendar: FormGroup = new FormGroup({});
  dateSelected: Date = new Date();

  ngOnInit(): void {
    console.log("EL ANCHO ES: ", this.width);
    this.dateNow = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() + 1);

    this.calendar.date$.subscribe((date: Date) => {
      this.dateSelected = this.storage.get('date') ? new Date(this.storage.get('date')) : date;

      if (this.exp === 4) {
        this.dates.getHorariosBC$(this.exp, this.dateSelected.toDateString()).subscribe((data: any) => {
          this.calendar.setHorariosBC(data.horasOcupadas);
        });
      }

      if ([2, 5, 6, 7].includes(this.exp)) {
        this.experience.getExperience(this.exp, this.dateSelected.toDateString()).subscribe((data: any) => {
          this.pasajeros.setMaximizePasajeros(data.cupos);
        });
      };

    });
    this.calendar.disabledDate$.subscribe((date: any) => {
      this.disabledDate = date;
    });

    this.myCalendar = new FormGroup({
      date: new FormControl(this.dateSelected)
    });
  }

  isDisabledDate(current: any): boolean {
    // console.log('La fecha actual es: ', current);
    const formattedDate = `${current.day}/${current.month + 1}/${current.year}`;
    // console.log('La fecha formateada es: ', formattedDate);

    const disabledFormattedDate = this.disabledDate.map((date: Date) => {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    });

    // console.log("Las fechas deshabilitadas formateadas son: ", disabledFormattedDate);
    return disabledFormattedDate.some(date => date === formattedDate);
  }

  onDateSelect(event: any): void {
    console.log('La fecha seleccionada es: ', event);
    this.storage.set('date', event);
    this.calendar.setDate(event);

    if (this.exp === 0 || this.exp === null) {
      this.exp = this.storage.get('exp') ? parseInt(this.storage.get('exp')) : 0;
    }

    // Bahia privada e islas privadas
    if (this.exp === 3 || this.exp === 1) {
      this.experience.getExperience(this.exp, event).subscribe((data: any) => {
        console.log('La experiencia es: ', data);
        this.calendar.setBarcosOcupados(data.BarcosOcupados);
      });
    }

    this.storage.set('adults', 1);
    this.storage.set('children', 0);

    this.pasajeros.setPasajeros(1, 0);
    this.facture.setAdults(1);
    this.facture.setChildren(0);
  }

  MonthChange(event: any): void {
    if (this.exp === 0 || this.exp === null) {
      this.exp = this.storage.get('exp') ? parseInt(this.storage.get('exp')) : 0;
    }

    this.dates.getDisponibilidad$(this.exp, event.month, event.year).subscribe((data: any) => {
      this.calendar.setDisabledDate(
        data.diasOcupados.map((dateString: string) => {
          const parts = dateString.split('-');
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // Restar 1 al mes, ya que en JavaScript los meses van de 0 a 11
          const day = parseInt(parts[2], 10);
          return new Date(year, month, day);
        })
      );
      console.log("Entro a consultar: ", this.exp, event.month + 1, event.year)
      console.log('Las fechas deshabilitadas son: ', data);
    });
  }
}
