import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewsService {

  constructor() { 
  }

  private isResponsiveView = new BehaviorSubject<boolean>(false);
  isResponsiveView$ = this.isResponsiveView.asObservable();

  setResponsiveView(value: boolean) {
    this.isResponsiveView.next(value);
  }

  getResponsiveView() {
    return this.isResponsiveView.value;
  }
}
