import { Component, Renderer2, ElementRef, AfterViewInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageService } from '@modules/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { SelectButtonChangeEvent } from 'primeng/selectbutton';
import { initializeHeader } from 'src/app/utils/header.utils';
import { CookieService } from 'ngx-cookie-service';
import { Overlay } from 'primeng/overlay';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatesService } from '@modules/landing/services/dates.service';
import { FiltersService } from '@modules/landing/services/filters.service';
import { CalendarService } from '@shared/components/calendar/calendar.service';
import { PasajerosService } from '@shared/components/pasajeros/pasajeros.service';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { LandingService } from '@modules/landing/services/landing.service';
import { FactureService } from '@modules/reservation/services/facture.service';
import { AuthService } from '@modules/auth/services/auth.service';
import { TypeStore } from '@core/enums/TypeStore';
import { RoutesProfile } from '@core/enums/TypeRoles';
import { MessageService } from 'primeng/api';
import { InfoService } from '@modules/reservation/components/info/service/info.service';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.css']
})
export class LandingHeaderComponent implements AfterViewInit {
  @ViewChild('op') op: Overlay | undefined;

  constructor(private router: Router, private translate: TranslateService, private renderer: Renderer2,
    private el: ElementRef, public langService: LanguageService, public cookies: CookieService,
    private dates: DatesService, private filters: FiltersService, private calendar: CalendarService,
    private pasajeros: PasajerosService, @Inject(LOCAL_STORAGE) private storage: StorageService,
    public landing: LandingService, private facture: FactureService, private auth: AuthService,
    private route: ActivatedRoute, private messageService: MessageService,
    private info: InfoService) { }

  now = new Date();
  dateNow = new Date();

  form: FormGroup = new FormGroup({});
  formRegister: FormGroup = new FormGroup({});

  optionsLanguage = [
    { value: 'es', label: 'Español' },
    { value: 'en', label: 'English' }
  ];

  formLogin: FormGroup = new FormGroup({});

  selectLanguage = this.cookies.get('lang') || 'es';

  experiencias: any[] = this.cookies.get('lang') !== 'en' ? [
    {
      id: 1,
      name: 'Tour Islas Privado',
      image: '../../../../../assets/images/experiencias/islasPrivado.jpg'
    },
    {
      id: 2,
      name: 'Tour Islas Compartido',
      image: '../../../../../assets/images/experiencias/islasComparido.jpg'
    },
    {
      id: 3,
      name: 'Tour Bahia Privado',
      image: '../../../../../assets/images/experiencias/bahiaPrivada.jpg'
    },
    {
      id: 4,
      name: 'Tour Bahia Compartido',
      image: '../../../../../assets/images/experiencias/bahiaCompartido.jpg'
    },
    {
      id: 5,
      name: 'Ecoturismo Privado',
      image: '../../../../../assets/images/experiencias/ecoturismo.jpg'
    },
    {
      id: 6,
      name: 'Ecoturismo Compartido',
      image: '../../../../../assets/images/experiencias/ecoturismo.jpg'
    },
    {
      id: 7,
      name: 'Dia de pesca',
      image: '../../../../../assets/images/experiencias/pesca.jpg'
    },
    {
      id: 8,
      name: 'Empresarial',
      image: '../../../../../assets/images/experiencias/empresarial.jpg'
    }
  ] : [
    {
      id: 1,
      name: 'Private Islands Tour',
      image: '../../../../../assets/images/experiencias/islasPrivado.jpg'
    },
    {
      id: 2,
      name: 'Shared Islands Tour',
      image: '../../../../../assets/images/experiencias/islasComparido.jpg'
    },
    {
      id: 3,
      name: 'Private Bay Tour',
      image: '../../../../../assets/images/experiencias/bahiaPrivada.jpg'
    },
    {
      id: 4,
      name: 'Shared Bay Tour',
      image: '../../../../../assets/images/experiencias/bahiaCompartido.jpg'
    },
    {
      id: 5,
      name: 'Private Ecotourism',
      image: '../../../../../assets/images/experiencias/ecoturismo.jpg'
    },
    {
      id: 6,
      name: 'Shared Ecotourism',
      image: '../../../../../assets/images/experiencias/ecoturismo.jpg'
    },
    {
      id: 7,
      name: 'Fishing Day',
      image: '../../../../../assets/images/experiencias/pesca.jpg'
    },
    {
      id: 8,
      name: 'Business',
      image: '../../../../../assets/images/experiencias/empresarial.jpg'
    }
  ]

  visible: boolean = false;
  register: boolean = false;
  visableEmpresarial: boolean = false;

  disabledDate: any[] = [];
  maximoPasajerosChildren: number = 0;

  codigoQR: string = '';

  // Modales de reset password
  resetPassword: boolean = false;
  resetPasswordEmail: boolean = false;
  resetPasswordMessage: boolean = false;
  resetPasswordOtp: boolean = false;

  ngOnInit() {
    this.form = new FormGroup({
      date: new FormControl({ value: null, disabled: true }, [Validators.required]),
      adults: new FormControl(0, [Validators.min(1)]),
      children: new FormControl(0),
      exp: new FormControl(null, [Validators.required]),
      code: new FormControl(''),
      pasajeros: new FormControl({ value: '', disabled: true })
    });

    this.formRegister = new FormGroup({
      type: new FormControl('client', [Validators.required]),
      nombre: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      apellido: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phone: new FormControl('', [Validators.required]),
      documento: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(15)]),
      id_tipo_documento: new FormControl('', [Validators.required]),
      pwdhash: new FormControl('', [Validators.required, Validators.minLength(8)]),
      direccion: new FormControl('', [Validators.required, Validators.minLength(5), Validators.maxLength(100)]),
      id_rol: new FormControl(1),
      offerts: new FormControl(true)
    });

    //Logica de codigos QR
    this.route.params.subscribe(params => {
      this.codigoQR = params['codigoQR'];
      if (this.codigoQR) {
        this.form.get('code')?.setValue(this.codigoQR.toString());
        this.form.get('code')?.disable();

      } else {
        this.form.get('code')?.enable();
      }
    });

    this.dateNow = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());

    this.filters.loginModal$.subscribe((visible: boolean) => {
      this.visible = visible;
    });

    this.formLogin = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });

    this.landing.getMaximizePasajeros().subscribe((maximizePasajeros: number) => {
      this.maximizePasajeros = maximizePasajeros;
      this.maximoPasajerosChildren = maximizePasajeros - 1;
    });
  }

  changeLanguage(language: SelectButtonChangeEvent) {
    const lang = language.value;
    this.translate.use(lang); // Asigno lenguaje global
    this.selectLanguage = lang; // Asigno lenguaje local
    this.langService.setLanguaje(lang); // Asigno lenguaje en el servicio
    this.cookies.set('lang', lang); // Asigno lenguaje en las cookies

    // Hago refresh de la pagina
    window.location.reload();
  }

  ngAfterViewInit() {
    initializeHeader(this.renderer, this.el.nativeElement);

    var nav: any = this.el.nativeElement.querySelector("#nav");
    var background_menu: any = this.el.nativeElement.querySelector("#back_menu");
    var btn_menu = this.el.nativeElement.querySelector("#btn_menu");
    var back_menu = this.el.nativeElement.querySelector("#back_menu");

    if (nav && background_menu && btn_menu && back_menu) {

      this.renderer.listen(btn_menu, 'click', () => mostrar_menu());
      this.renderer.listen(back_menu, 'click', () => ocultar_menu());

      const mostrar_menu = () => {
        this.renderer.setStyle(nav, 'right', '0px');
        this.renderer.setStyle(background_menu, 'display', 'block');
      };

      const ocultar_menu = () => {
        this.renderer.setStyle(nav, 'right', '-900px');
        this.renderer.setStyle(background_menu, 'display', 'none');
      };
    } else {
      console.log("Some elements are missing");
    }
  }


  scrollToSection(sectionId: any) {
    var targetSection = document.getElementById(sectionId);

    if (targetSection) {
      var offset =
        targetSection.offsetTop - (document.getElementById("menu")?.offsetHeight || 0);

      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  }

  closeDialog() {
    this.storage.remove('loginModal');
    this.filters.setLoginModal(false);
  }

  openDialog() {
    this.filters.setLoginModal(true);
  }

  openRegister() {
    this.register = true;
    this.info.getDocumentTypes().subscribe((data: any) => {
      this.documentOptions = data;
      console.log(this.documentOptions);
    });
    this.formRegister.reset();
  }

  createRegister() {
    if(this.formRegister.get('type')?.value === 'client') {
      this.formRegister.get('id_rol')?.setValue(1);
    }else{
      this.formRegister.get('id_rol')?.setValue(2);
    }

    // change to string
    this.formRegister.get('documento')?.setValue(this.formRegister.get('documento')?.value.toString());
    this.formRegister.get('phone')?.setValue(this.formRegister.get('phone')?.value.toString());

    this.formRegister.get('offerts')?.setValue(true);
    
    this.auth.registerUser$(this.formRegister.value).subscribe((data: any) => {
      console.log('Data: ', data);
      if (!data.exists) {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message, life: 3000 });
        this.register = false;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: data.message, life: 3000 });
      }
    });
  };

  closeEmpresarial() {
    this.visableEmpresarial = false;
  }

  toReservation() {
    console.log('La experiencia es: ', this.form.value.exp.id, this.form.value.exp.name);
    this.storage.set('adults', this.form.get('adults')?.value);
    this.storage.set('children', this.form.get('children')?.value);
    this.storage.set('date', this.form.get('date')?.value);
    this.storage.set('exp', this.form.value.exp.id);
    this.storage.set('boat', 0);
    this.storage.set('code', this.form.get('code')?.value);

    this.filters.setFiltersLanding({
      exp: this.form.value.exp.id,
      date: this.form.get('date')?.value,
      adults: this.form.get('adults')?.value,
      children: this.form.get('children')?.value
    });

    this.facture.setAdults(this.form.get('adults')?.value);
    this.facture.setChildren(this.form.get('children')?.value);

    console.log('Los filtros son: ', this.filters.getFiltersLanding());
    this.router.navigate(['/reservas']);
  }

  showKids: boolean = false;

  experienceSelected(exp: any) {
    this.form.get('date')?.enable();
    this.form.get('date')?.setValue(null);
    this.form.get('pasajeros')?.setValue('1 adulto, 0 niños');
    this.form.get('adults')?.setValue(1);
    this.form.get('children')?.setValue(0);
    this.form.get('pasajeros')?.disable();

    const expWithValidation = [1, 2, 3, 4, 5, 6, 7];

    if (expWithValidation.includes(exp.id)) {
      this.dates.getDisponibilidad$(exp.id, this.now.getMonth() + 1, this.now.getFullYear()).subscribe((data: any) => {
        this.disabledDate = data.diasOcupados.map(
          (dateString: string) => {
            const parts = dateString.split('-');
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1; // Restar 1 al mes, ya que en JavaScript los meses van de 0 a 11
            const day = parseInt(parts[2], 10);
            return new Date(year, month, day);
          }

        );

        this.calendar.setDisabledDate(this.disabledDate);
        console.log('Las fechas deshabilitadas son: ', this.disabledDate);
      });
      console.log('La experiencia es: ', exp.id);
      if (exp.id === 4) {
        this.showKids = true;
      } else {
        this.showKids = false;
      }
    } else {
      this.form.get('exp')?.setValue(null);
      // Empresarial
      this.visableEmpresarial = true;
      setTimeout(() => {
        window.open('https://wa.me/573052765639', '_blank');
        this.visableEmpresarial = false;
      }, 5000);
    }
  }

  isDisabledDate(current: any): boolean {
    // console.log('La fecha actual es: ', current);
    const formattedDate = `${current.day}/${current.month + 1}/${current.year}`;
    // console.log('La fecha formateada es: ', formattedDate);

    const disabledFormattedDate = this.disabledDate.map((date: Date) => {
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    });

    // console.log("Las fechas deshabilitadas formateadas son: ", disabledFormattedDate);
    return disabledFormattedDate.some(date => date === formattedDate);
  }

  setPasajeros() {
    const adults = this.form.get('adults')?.value;
    const children = this.form.get('children')?.value;
    if(adults + children > this.maximizePasajeros) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'El número de pasajeros excede el máximo permitido', life: 3000 });
      this.form.get('adults')?.setValue(1);
      this.form.get('children')?.setValue(0);
      this.form.get('pasajeros')?.setValue('1 adulto, 0 niños');
      return;
    }
    this.form.get('pasajeros')?.setValue(`${adults} adultos, ${children} niños`);
    this.pasajeros.setPasajeros(adults, children);
  }

  setAdults(adults: any) {
    if (adults >= this.maximizePasajeros) {
      console.log("Adults: ", adults, "Maximo de pasajeros: ", this.maximizePasajeros)
      this.form.get('children')?.setValue(0);
      this.maximoPasajerosChildren = 0;
    } else {
      let resta = this.maximizePasajeros - adults;
      console.log("Resta: ", resta)
      this.maximoPasajerosChildren = resta;
      // this.form.get('children')?.setValue(resta);
    }
  }

  MonthChange(event: any): void {

    this.dates.getDisponibilidad$(this.form.value.exp.id, event.month, event.year).subscribe((data: any) => {
      this.calendar.setDisabledDate(
        data.diasOcupados.map((dateString: string) => {
          const parts = dateString.split('-');
          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // Restar 1 al mes, ya que en JavaScript los meses van de 0 a 11
          const day = parseInt(parts[2], 10);
          return new Date(year, month, day);
        })
      );

      this.disabledDate = data.diasOcupados.map((dateString: string) => {
        const parts = dateString.split('-');
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1; // Restar 1 al mes, ya que en JavaScript los meses van de 0 a 11
        const day = parseInt(parts[2], 10);
        return new Date(year, month, day);
      });

      console.log("Entro a consultar: ", this.form.value.exp.id, event.month, event.year)
      console.log('Las fechas deshabilitadas son: ', this.disabledDate);
    });
  }

  maximizePasajeros: number = 0;

  onDateSelect($event: any) {
    this.form.get('pasajeros')?.enable();
    this.form.get('pasajeros')?.setValue('1 adulto, 0 niños');
    this.form.get('adults')?.setValue(1);
    this.form.get('children')?.setValue(0);
    this.calendar.setDate($event);

    this.landing.getCupos(this.form.value.exp.id, $event).subscribe((data: any) => {
      this.storage.set('maximizePasajeros', data.cupos);
      this.landing.setMaximizePasajeros(data.cupos);
      this.pasajeros.setMaximizePasajeros(data.cupos);

      console.log("Maximo de pasajeros: ", data.cupos);
    });
  }

  login() {
    this.auth.loginUser$(this.formLogin.value).subscribe((data: any) => {
      console.log('Data: ', data);
      if (data.status) {
        this.closeDialog();
        this.storage.remove('loginModal');
        this.storage.set(TypeStore.CURRENT_USER, data.user);

        this.auth.getRoles$(data.user.id_user).subscribe((data) => {
          console.log(RoutesProfile[data.rol][0]);
          this.router.navigate([RoutesProfile[data.rol][0]]);
        });

      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: data.message, life: 3000 });
      }
    });
  }

  email: string = '';

  generateCode() {
    this.auth.generateCodeEmail$(this.email).subscribe((data: any) => {
      if (data.status) {
        this.resetPasswordEmail = false;
        this.resetPasswordOtp = true;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: data.message, life: 3000 });
      }
    });

    this.msjComprobante = '';
    this.msjColor = 'BLACK';
    this.buttomSend = true;
    this.code = '';
  }

  msjComprobante: string = '';
  msjColor: string = 'BLACK';
  buttomSend: boolean = true;
  code: string = '';

  sendCode() {
    this.msjComprobante = "Comprobando código...";
    this.buttomSend = true;

    this.auth.verifyCodeEmail$(this.email, this.code).subscribe((data: any) => {
      console.log(data)
      this.msjComprobante = data.message;
      if (data.status) {
        this.msjColor = "GREEN";
        setTimeout(() => {
          this.resetPasswordOtp = false;
          this.resetPasswordMessage = true;
        }, 2000);
      } else {
        this.msjColor = "RED";
        this.buttomSend = true;
        this.code = "";
      }
    });
  }

  inputCode(code: any) {
    const numeroArray = code.value.split('').map(Number);

    if (numeroArray.length == 4) {
      this.buttomSend = false;
      this.code = code.value;
    } else {
      this.buttomSend = true;
    }
  }

  pwd: string = '';

  resetPwd() {
    this.auth.changePassword$(this.email, this.pwd, this.code).subscribe((data: any) => {
      if (data.status) {
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: data.message, life: 3000 });
        this.resetPasswordMessage = false;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: data.message, life: 3000 });
      }
    });
  }

  // Registro
  typeRegister: { value: string, label: string }[] = [{
    value: 'client', label: 'Cliente'
  }, {
    value: 'costumer', label: 'Vendedor'
  }];

  typeRegisterSelected(event: any) {
    console.log(event.value);
  }

  documentOptions: any;

}
