import { Component, Input } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { IUser } from '@core/models/IUser';
import { AuthService } from '@modules/auth/services/auth.service';
import { TypeRole } from '@core/enums/TypeRoles';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrl: './header-menu.component.css'
})
export class HeaderMenuComponent {
  @Input() user: IUser | undefined;
  constructor(private auth: AuthService) { }

  items: MenuItem[] = [];

  ngOnInit() {
    this.auth.getRoles$(this.user!.id_user).subscribe((role: any) => {
      if (role.rol === TypeRole.client) {
        this.items = [
          {
            label: 'Menu',
            icon: 'pi pi-fw pi-globe',
            expanded: true,

            items: [
              { label: 'Informacion', icon: 'pi pi-fw pi-chart-pie', routerLink: '/dashboard/client/info' },
              { label: 'Reservas', icon: 'pi pi-fw pi-file', routerLink: '/dashboard/client/reservas' }
            ]
          }
        ];
      }

      if (role.rol === TypeRole.costumer) {
        this.items = [
          {
            label: 'Menu',
            icon: 'pi pi-fw pi-globe',
            expanded: true,

            items: [
              { label: 'Informacion', icon: 'pi pi-fw pi-chart-pie', routerLink: '/dashboard/costumer/info' },
              { label: 'Reservas', icon: 'pi pi-fw pi-file', routerLink: '/dashboard/costumer/reservas' },
              { label: 'Comisiones', icon: 'pi pi-fw pi-money-bill', routerLink: '/dashboard/costumer/comisiones' }
            ]
          }
        ];
      }

      if (role.rol === TypeRole.admin) {
        this.items = [
          {
            label: 'Menu',
            icon: 'pi pi-fw pi-globe',
            expanded: true,

            items: [
              { label: 'Informacion', icon: 'pi pi-fw pi-chart-pie', routerLink: '/dashboard/admon/info' },
              { label: 'Reservas', icon: 'pi pi-fw pi-file', routerLink: '/dashboard/admon/reservas' },
              { label: 'Comisiones', icon: 'pi pi-fw pi-money-bill', routerLink: '/dashboard/admon/comisiones' },
              { label: 'Calendario', icon: 'pi pi-fw pi-users', routerLink: '/dashboard/admon/calendario' },
              { label: 'Parametricas', icon: 'pi pi-fw pi-plus', routerLink: '/dashboard/admon/parametricas' }
            ]
          }
        ];
      }
    });

  }
}
