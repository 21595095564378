import { TypeRoutes } from './TypeRoutes';

export enum TypeRole {
    client = 1,
    admin = 3,
    costumer = 2,
}

export const RoutesProfile: { [key: number]: string[] } = {
    [TypeRole.client]: [TypeRoutes.client],
    [TypeRole.costumer]: [TypeRoutes.costumer],
    [TypeRole.admin]: [TypeRoutes.admin],
};