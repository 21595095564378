import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { TypeStore } from '@core/enums/TypeStore';
import { IUser } from '@core/models/IUser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService, private router: Router) { }

  getCurrentUser() {
    const currentUser: IUser = this.storage.get(
      TypeStore.CURRENT_USER
    );

    return currentUser;
  }

  logout(): boolean {
    this.storage.remove(TypeStore.CURRENT_USER);
    this.storage.remove(TypeStore.TOKEN);
    this.storage.set('loginModal', true);
    this.router.navigate(['/']);

    return false;
  }
}
