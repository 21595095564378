import { Injectable } from '@angular/core';
import axios from 'axios';
import { Observable, from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment.development';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private apiKey = environment.azure_api_key;
  private endpoint = 'https://api.cognitive.microsofttranslator.com';
  private location = 'eastus';

  constructor(private cookies: CookieService) { }

  private from_lang = this.cookies.get('lang') === 'es' ? 'en' : 'es';
  

  translateText(text: string, targetLang: string): Observable<string> {

    return from(
      axios({
        baseURL: this.endpoint,
        url: '/translate',
        method: 'post',
        headers: {
          'Ocp-Apim-Subscription-Key': this.apiKey,
          'Ocp-Apim-Subscription-Region': this.location,
          'Content-Type': 'application/json',
          'X-ClientTraceId': this.generateClientTraceId()
        },
        params: {
          'api-version': '3.0',
          'from': this.from_lang, // Puedes ajustar el idioma de origen según sea necesario
          'to': targetLang // Idioma de destino, no como array
        },
        data: [{
          'text': text
        }],
        responseType: 'json'
      })
    ).pipe(
      map(response => {
        // Extraer la traducción del JSON de respuesta
        return response.data[0]?.translations[0]?.text || 'Translation failed';
      }),
      catchError(() => {
        return of('Translation failed');
      })
    );
  }

  private generateClientTraceId(): string {
    // Generar un ID de traza único (puedes usar cualquier método para esto)
    return Math.random().toString(36).substring(2, 15);
  }
}