import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-contact',
  templateUrl: './landing-contact.component.html',
  styleUrls: ['./landing-contact.component.css', './responsive.css']
})
export class LandingContactComponent {
  rediriectToAddres() {
    window.open('https://maps.app.goo.gl/CjSKAifVbGLMjhYc6', '_blank');
  }

  photos: string[] = ['/assets/images/agencia/agencia.png', '/assets/images/agencia/agencia2.png', '/assets/images/agencia/agencia3.png'];
}
