import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { finalize, catchError } from 'rxjs/operators';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private loading: LoadingService) { }

  private requests: { request: HttpRequest<any>, identifier: number }[] = [];

  removeRequest(request: HttpRequest<any>) {
    const i = this.requests.findIndex(entry => entry.request === request);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loading.setDelayComplete(this.requests.length > 0 ? this.requests[0].identifier : 0, this.requests.length > 0 ? this.requests[1].identifier : 0);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const identifier = Math.random(); // Genera un identificador único
  
    // Solo agregar el encabezado 'X-Request-Identifier' si la solicitud no es a la API de DeepL
    if (!request.url.startsWith('https://api-free.deepl.com')) {
      request = request.clone({ setHeaders: { 'X-Request-Identifier': identifier.toString() } });
      this.loading.setLoading(true);
    }
  
    this.requests.push({ request, identifier });
  
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Error en la solicitud', error); // Registra el error para depuración
        this.removeRequest(request);
        return throwError(() => error); // Propaga el error usando throwError
      }),
      finalize(() => {
        this.removeRequest(request);
      })
    );
  }
}
