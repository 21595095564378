// Declaración de variables globales
let slides: NodeListOf<Element>;
let totalSlides: number;
let currentSlide: number = 0;
let slideInterval: number = 3000; // Intervalo de cambio de diapositivas en milisegundos
let autoPlay: any;

export function initializeCarousel() {
    slides = document.querySelectorAll(".slide");
    totalSlides = slides.length;

    startAutoPlay();

    const arrowLeft = document.querySelector(".arrow-left");
    if (arrowLeft) {
        arrowLeft.addEventListener("click", () => {
            pauseAutoPlay();
            prevSlide();
        });
    }

    const arrowRight = document.querySelector(".arrow-right");
    if (arrowRight) {
        arrowRight.addEventListener("click", () => {
            pauseAutoPlay();
            nextSlide();
        });
    }

    const indicatorsContainer = document.querySelector(".indicators");
    if (indicatorsContainer) {
        indicatorsContainer.addEventListener("click", (e: any) => {
            if (e.target.classList.contains("indicator")) {
                pauseAutoPlay();
                const clickedIndex = parseInt(e.target.getAttribute("data-index"));
                showSlide(clickedIndex);
            }
        });
    }

    const carousel = document.querySelector(".carousel");
    if (carousel) {
        carousel.addEventListener("mouseenter", () => pauseAutoPlay());
        carousel.addEventListener("mouseleave", () => startAutoPlay());
    }
}

function showSlide(index: number) {
    if (slides[currentSlide] && slides[index]) {
        slides[currentSlide].classList.remove("active");
        slides[index].classList.add("active");
        currentSlide = index;
        updateIndicators();
    }
}

function nextSlide() {
    const nextIndex = (currentSlide + 1) % totalSlides;
    if (totalSlides > 0) {
        showSlide(nextIndex);
    }
}

function prevSlide() {
    const prevIndex = (currentSlide - 1 + totalSlides) % totalSlides;
    showSlide(prevIndex);
}

function startAutoPlay() {
    autoPlay = setInterval(() => nextSlide(), slideInterval);
}

function pauseAutoPlay() {
    clearInterval(autoPlay);
}

function updateIndicators() {
    const indicators = document.querySelectorAll(".indicator");
    indicators.forEach((indicator, index) => {
        if (index === currentSlide) {
            indicator.classList.add("active");
        } else {
            indicator.classList.remove("active");
        }
    });
}

// Función para añadir una diapositiva específica
export function addSpecificSlide(index: any) {
    // Verificar si el índice está dentro del rango de las diapositivas
    if (index >= 0 && index < totalSlides) {
        showSlide(index);
    }
}

